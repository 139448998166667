.App { 
  text-align: center;
}

body {
  height: 100%;
  background: rgb(255, 255, 131);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.landing-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in element width */
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%; /* Ensure the navbar takes up the full width */
}

.navbar a {
  text-decoration: none;
  color: #fff;
}

.navbar li a:hover {
  background-color: #fff;
  color: black;
  border-radius: 30px;
}

.Logo {
  height: auto;
  width: auto;
  max-height: 45px;
  max-width: 250px;
}

.dropdown {
  text-align: center;
  background-color: rgb(92, 2, 92);
  box-shadow: 0 1px 1px 0 #333232;
}

.dropdown__button {
  background-color: rgb(92, 2, 92);
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 16px;
}

.dropdown__menu {
  background-color: rgb(92, 2, 92);
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
}

.dropdown__option {
  cursor: pointer;
  font-size: 16px;
}

.dropdown__option:hover {
  background-color: #fff;
}

.hero-section {
  position: relative;
  padding: 20px;
  background-color: #8a2be2;
  color: #fff;
  margin: 20px auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  border: 1px solid #530f77;
  overflow: hidden;
  font-size: 20px;
}

.hero-section::before,
.hero-section::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 15px;
  background: repeating-linear-gradient(90deg, #6a0dad, #6a0dad 5px, #fff 5px, #fff 10px);
  z-index: 2;
}

.hero-section::before {
  top: -10px;
}

.hero-section::after {
  bottom: -10px;
}

.hero-content {
  text-align: center;
}

.hero-button {
  padding: 10px 20px;
  border: none;
  background-color: rgb(92, 2, 92);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: auto;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 20px;
}

.hero-button a {
  text-decoration: none;
  color: #fff;
}

.hero-button:hover {
  background-color: #7b68ee;
}

.hero-section h1,
.hero-section h2,
.hero-section h3,
.hero-section p {
  color: #fff;
  margin: 10px 0;
}

.sections-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

.about-section,
.raffle-section,
.information-section {
  background-color: #8a2be2;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  flex-basis: calc(80% - 5px);
  box-sizing: border-box;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-section p,
.raffle-section p,
.information-section p {
  font-size: 20px;
}

.about-section h2,
.raffle-section h2,
.information-section h2 {
  color: #fff;
  font-size: 24px;
  text-align: center;
}

.about-section ol,
.information-section ol,
.raffle-section ol {
  padding-left: 20px;
  font-size: 20px;
}

.about-section li,
.information-section li,
.raffle-section li {
  margin-bottom: 10px;
}

.information-section .info-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 10px 0;
}

.raffle-section {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
  margin: 20px 0;
}

.raffle-section iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.raffle-section::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 24px;
  z-index: 1;
}

.gallery-section {
  margin: 100px 0;
  padding: 20px;
  background-color: #000;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.gallery-section h2 {
  font-size: 36px;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #fff;
}

.gallery-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust grid for smaller screens */
  grid-gap: 20px;
}

.gallery-images img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.social-icons {
}

@media screen and (max-width: 768px) {
  .raffle-section {
    padding-bottom: 133.33%;
  }

  .about-section,
  .raffle-section,
  .information-section {
    flex-basis: 90%;
    margin-bottom: 20px;
  }
}

footer {
  background-color: rgb(92, 2, 92);
  color: white;
  padding: 10px;
  text-align: center;
}
